import PropTypes from "prop-types";
import React from "react";
import Nav from "../components/nav";
import PageTitle from "./pageTitle";

const Header = ({ handleLogin }) => (
  <header>
    <Nav handleClick={handleLogin} />
    <PageTitle
      title={
        <h1 className="text-white uppercase">
          Feel <span className="font-bold">invested</span>
          <br /> in our business
        </h1>
      }
    />
  </header>
);

Header.propTypes = {
  siteTitle: PropTypes.string
};

Header.defaultProps = {
  siteTitle: ``
};

export default Header;

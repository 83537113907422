import React,{useState} from 'react'
import ModalVideo from 'react-modal-video';
import iconVideoGuide from "../images/icon_video_guide.svg";

const GuideVideo = ({ videoData }) => {

	const [isOpen, setOpen] = useState(false)
	
	if (typeof window !== 'undefined') {
		return (
			
			<React.Fragment>
				<ModalVideo channel='vimeo' autoPlay isOpen={isOpen} videoId={ videoData.id } onClose={() => setOpen(false)} />
				<img src={ iconVideoGuide } style={{ height: "60px", width: "80px", marginBottom: "15px" }} alt="{ videoData.title }" />
				<p><strong>{ videoData.title }</strong></p>
				<a className="button button--download" href="javascript:void(0)" onClick={()=> setOpen(true)}>
					Watch
				</a>
			</React.Fragment>
			
		)
	}
	return null;
}

export default GuideVideo;
import React, { Component } from "react";
import backgroundImage from "../images/background-video.png";
import backgroundImageLarge from "../images/grey-swoosh.png";

class Video extends Component {
  constructor(props) {
    super(props);
    this.setVideoMinHeight = this.setVideoMinHeight.bind(this);
  }

  componentDidMount() {
    window.addEventListener("load", this.setVideoMinHeight);
    window.addEventListener("resize", this.setVideoMinHeight);
  }
  setVideoMinHeight() {
    let videoHeight = document.querySelector(
      ".cola-shareplan__video-container .content-container"
    ).clientHeight;
    document.querySelector(".cola-shareplan__video-container").style.minHeight =
      videoHeight + "px";
  }
  render() {
    return (
      <div className="cola-shareplan__video-container">
        <picture>
          <source media="(max-width: 1442px)" srcSet={backgroundImage} />
          <source media="(min-width: 1443px)" srcSet={backgroundImageLarge} />
          <img src={backgroundImage} alt="" />
        </picture>

        <div className="content-container">
          <h2 className="text-center video-title">
            Bringing our Share Plan to life
          </h2>
          <div className="cola-shareplan__video">
            {/* <iframe
              title="YouTube Video"
              width="560"
              height="315"
              src="https://www.youtube.com/embed/8_XBxcIoZy0"
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe> */}
            <iframe
              src="https://player.vimeo.com/video/502966000"
              width="640"
              height="360"
              frameBorder="0"
              allow="autoplay; fullscreen"
              allowFullScreen
              title="Video"
            ></iframe>
          </div>
        </div>
      </div>
    );
  }
}

export default Video;

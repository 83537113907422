import React from "react";
import headerImage from "../images/header-background.png";
import headerImageMobile from "../images/mobile-swoosh.png";
import shareplanLogo from "../images/rewards-share-plan.svg";
import purpleSwoosh from "../images/purple-swoosh-2.svg";

const PageTitle = ({ title }) => (
  <div className="cola-shareplan__page-title-container">
    <picture>
      <source media="(max-width: 599px)" srcSet={headerImageMobile} />
      <source media="(min-width: 600px)" srcSet={headerImage} />
      <img
        className="cola-shareplan__page-title-background"
        src={headerImage}
        alt="Feel invested in our business"
      />
    </picture>

    <div className="cola-shareplan__page-title">
      <img src={purpleSwoosh} style={{ width: "100%" }} alt="" />
      <img
        className="cola-shareplan__rewards-logo"
        src={shareplanLogo}
        alt="Rewards Share Plan"
      />
      <div>{title}</div>
    </div>
  </div>
);

export default PageTitle;

import React from "react";
import Card from "./card";
import overview from "../images/overview.svg";
import faq from "../images/faq.svg";
import shareworksGuide from "../images/shareworks-guide.svg";
import sharePlanComparison from "../images/share-plan-comparison.svg";
import purpleSwoosh from "../images/purple-swoosh.svg";
import faqs from "../files/CCEP_FAQs_2022.pdf";
import booklet from "../files/CCEP_Share_Plan_Booklet_2022.pdf";
import shareworks from "../files/CCEP_Shareworks_Guide_2022.pdf";
import comparison from "../files/CCEP_Share_Plan_Comparison_2022.pdf";
import GuideVideo from "./guideVideo";

const SharePlanInfo = () => (
  <div
    id="downloads"
    className="share-plan-info nav-item"
    style={{
      backgroundImage: `url(${purpleSwoosh})`,
      backgroundRepeat: "no-repeat",
      backgroundSize: "101%",
      backgroundPosition: "bottom"
    }}
  >
    <div className="content-container">
      <h2>Useful Share Plan Info</h2>

      <div className="share-plan-info__card-container">
        <Card className="share-plan-info__card">
          <GuideVideo videoData={{id: "706878064", title: "Navigating your Shareworks Account"}} />
        </Card>
        <Card className="share-plan-info__card">
          <GuideVideo videoData={{id: "706877622", title: "Updating your Personal Details"}} />
        </Card>
        <Card className="share-plan-info__card">
          <GuideVideo videoData={{id: "706878446", title: "A Participants Guide to Transacting"}} />
        </Card>
      </div>
      <div className="share-plan-info__card-container">
        <Card className="share-plan-info__card">
          <img src={overview} style={{ height: "82px", width: "115px" }} alt="" />
          <a className="button button--download" href={booklet} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        </Card>
        <Card className="share-plan-info__card">
          <img src={faq} style={{ height: "82px", width: "115px" }} alt="" />
          <a className="button button--download" href={faqs} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        </Card>
        <Card className="share-plan-info__card">
          <img src={shareworksGuide} style={{ height: "82px", width: "115px" }} alt="" />
          <a className="button button--download" href={shareworks} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        </Card>
        <Card className="share-plan-info__card">
          <img src={sharePlanComparison} style={{ height: "82px", width: "115px" }} alt="" />
          <a className="button button--download" href={comparison} target="_blank" rel="noopener noreferrer">
            Download
          </a>
        </Card>
      </div>
    </div>
  </div>
);

export default SharePlanInfo;

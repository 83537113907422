import React from "react";

const Popup = ({ showPopup, handleClosePopup }) => (
  <div
    onClick={e => handleClosePopup(e)}
    className={
      "cola-shareplan__overlay" +
      (showPopup ? " cola-shareplan__overlay--show" : "")
    }
  >
    <div
      className={
        "cola-shareplan__popup" +
        (showPopup ? " cola-shareplan__popup--show" : "")
      }
    >
      <h2>Great to see you want to join</h2>
      <p>
        To join you just need to register for an account with Shareworks and once 
        that's sorted, you can login and follow the simple joining instructions 
        from there. You can contact Shareworks via phone:
      </p>
      <p>
        UK: <span className="font-bold">08-082349514</span> (8am to 6pm GMT)<br />
        International (EMEA): <span className="font-bold">+44 20 7425 1075</span> (8am to 6pm GMT)<br />
        International (NA): <span className="font-bold">1 403 515 3909</span> (6am to 6pm MST)
      </p>

      <div className="cola-shareplan__popup-cta flex items-center justify-between">
        <p className="m-0 p-0 font-bold">Already have a Shareworks Account?</p>
        <a
          href="https://myapps.microsoft.com/signin/Solium/9ff7b889-a91e-430d-b4e7-26c2bd34b2cd?tenantId=c3549632-51ee-40fe-b6ae-a69f3a6cc157"
          target="_blank"
          className="button"
          rel="noopener noreferrer"
        >
          Log-in
        </a>
      </div>
    </div>
  </div>
);

export default Popup;

import React from "react";
import contactIcon from "../images/icon_contact.svg";
import continuousIcon from "../images/icon_continuous.svg";
//import calendarIcon from "../images/calender-28.svg";
//import calendarIconSecond from "../images/calendar-13.svg";
import shareworksIcon from "../images/msaw_shareworks_logo.png";
//import Banner from "./banner";
import Card from "./card";
import backgroundImg from "../images/white-curve.svg";
import shareworks from "../files/CCEP_Shareworks_Guide_2021.pdf";

const JoiningProcess = ({ handleLogin }) => (
  <section
    id="joining"
    className="joining-process grey-bg nav-item"
    style={{
      backgroundImage: `url(${backgroundImg})`,
      backgroundRepeat: "no-repeat",
      backgroundPosition: "center bottom -1px",
      backgroundSize: "100%"
    }}
  >
    <div className="content-container">
      <h2 className="text-center font-bold">Discover the joining process</h2>
      <div style={{ maxWidth: "880px", margin: "auto" }}>
        <p className="text-center" style={{marginBottom: "50px"}}>
          Thinking of joining our Share Plan? You can sign up in three simple steps:
        </p>

        {/*<Banner />*/}
      </div>

      <div className="joining-process__card-container">
        <Card className="joining-process__card">
          <img
            src={contactIcon}
            style={{
              width: "100px",
              height: "auto",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            alt=""
          />
          <p style={{marginBottom: "20px"}}>
          To join the plan, you will need to activate your Shareworks account. Instructions can be found <a href={shareworks} target="_blank" rel="noopener noreferrer" className="font-bold">here</a>, or you can contact Shareworks via phone.
          </p>
          <p style={{margin: "0 10px 10px"}}>UK: <span className="font-bold">08-082349514</span><br />(8am to 6pm GMT)</p>
          <p style={{margin: "0 10px 10px"}}>International (EMEA): <span className="font-bold">+44 20 7425 1075</span><br />(8am to 6pm GMT)</p>
          <p style={{margin: "0 10px 10px"}}>International (NA): <span className="font-bold">1 403 515 3909</span><br />(6am to 6pm MST)</p>
          <p>Chat facility available<br /> once you <a href="javascript:void(0)" className="font-bold" style={{textDecoration: "underline"}} onClick={() => handleLogin()}>join</a>.</p>
        </Card>

        <Card className="joining-process__card">
          <img
            src={shareworksIcon}
            style={{
              width: "250px",
              height: "auto",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            alt=""
          />
          <p>
            You'll receive an email from our share plan administrators,
            Shareworks by Morgan Stanley to register. Look out for an email 
            from: <a href="mailto:shareworks_notification@shareworks.com" className="font-bold" style={{fontSize: "12px"}}>shareworks_notification@shareworks.com</a>. 
            Follow the steps on Shareworks to set up your account and your investments. 
            Refer to our supporting material below if you require assistance.
          </p>
        </Card>

        <Card className="joining-process__card">
          <img
            src={continuousIcon}
            style={{
              width: "100px",
              height: "auto",
              marginLeft: "auto",
              marginRight: "auto"
            }}
            alt=""
          />
          <p>
          You can join the Share Plan at any time, and your investments 
          will begin from the next applicable payroll cycle, once registered.
          </p>
        </Card>
      </div>
      <div
        className="flex justify-center"
        style={{ marginTop: "50px", paddingBottom: "38px" }}
      >
        <a className="button" onClick={() => handleLogin()}>
          Join Now
        </a>
      </div>
    </div>
  </section>
);

export default JoiningProcess;

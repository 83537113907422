import React, { Component } from "react";
import ccepLogo from "../images/ccep-logo.png";
import "../styles/global.scss";
import Layout from "../components/layout";
import Video from "../components/video";
import KeyFeatures from "../components/keyFeatures";
import JoiningProcess from "../components/joiningProcess";
//import ShareShopSharePlan from "../components/shareshopShareplan";
//import Testimonials from "../components/testimonials";
import SharePlanInfo from "../components/sharePlanInfo";
import Header from "../components/header";
import Popup from "../components/popup";

class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false
    };

    this.showPopup = this.showPopup.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
  }

  showPopup() {
    this.setState({ showPopup: true });
  }

  hidePopup(e) {
    if (!e.target.classList.contains("cola-shareplan__overlay")) return;
    this.setState({ showPopup: false });
  }

  render() {
    return (
      <Layout>
        <Popup
          showPopup={this.state.showPopup}
          handleClosePopup={this.hidePopup}
        />
        <div id="overview">
          <Header handleLogin={this.showPopup} />
          <div className="nav-item content-container">
            <p className="text-center">
              {/* Our Share Plan forms part of our
              <span className="bold-purple"> ‘BEING REWARDED’</span> offering
              under
              <span className="font-medium"> ME@CCEP</span>. */}
              <span className="font-medium">Feel invested in our business</span>
            </p>
            <br />
            <p className="text-center">
              Whether you help make, move or sell our products, you all play a
              unique part in our success. So, we believe it’s only right that
              you have the opportunity to own your share of our unique company,
              through our GB Share Plan.
            </p>
            <br />
            <p className="text-center">
              This website should provide you with information on the Share Plan
              so you can make an informed decision as to whether you wish to
              invest.
            </p>
          </div>

          <img className="block mt-10 ccep-logo" src={ccepLogo} alt="" />

          <Video />
        </div>

        <KeyFeatures />

        <JoiningProcess handleLogin={this.showPopup} />

        {/* <Testimonials />

        <ShareShopSharePlan /> */}

        <SharePlanInfo />
      </Layout>
    );
  }
}

export default IndexPage;
